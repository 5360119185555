<template>
  <div class="fragment">

    <template v-if="$store.getters.getMyPayments.length > 0 && $store.getters.getMyPaymentsLoading !== true">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'mypayments_number',
          'mypayments_created',
          'mypayments_customer',
          'mypayments_transaction',
          'mypayments_sum',
          'mypayments_sumToPay',
          'warehouseManagement_manage',
        ])"></span>
        <table class="site-table"  v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th></th>
            <th>{{$t('mypayments_number.localization_value.value')}}</th>
            <th
                v-if="$store.getters.getCurrentUserRights.additionalPermissions &&
                $store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]"
            >
              {{$t('common_user.localization_value.value')}}
            </th>
            <th>{{$t('mypayments_created.localization_value.value')}}</th>
            <th v-if="!userTabs.payoneer.active">{{$t('mypayments_customer.localization_value.value')}}</th>
            <th>{{$t('mypayments_transaction.localization_value.value')}}</th>
            <th>{{$t('mypayments_sum.localization_value.value')}}</th>
            <th v-if="!userTabs.payoneer.active">{{$t('mypayments_sumToPay.localization_value.value')}}</th>
            <th width="100%"></th>
            <th v-if="userTabs.authorize.active">
              {{$t('warehouseManagement_manage.localization_value.value')}}
            </th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getMyPayments" :key="index">
            <td>
              <DefaultCheckbox
                  class="empty-label"
                  :selectedNow="selectedNow"
                  :dataValue="item.id"
              />
            </td>
            <td>
              <span class="table-link btn-style" @click="showTransactionInfo(item.id)">
                #{{item.id}}
              </span>
            </td>
            <td
                v-if="$store.getters.getCurrentUserRights.additionalPermissions &&
                $store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]"
            >
              <TableUserColumn
                  :item="item"
              />
            </td>
            <td>
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </td>
            <td v-if="!userTabs.payoneer.active">
              <template v-if="item.payment_transaction_detail &&
                item.payment_transaction_detail.payment_transaction_contact !== null">
                  {{item.payment_transaction_detail.payment_transaction_contact.full_name}}
              </template>
            </td>
            <td>
              {{item.transaction_id}}
            </td>
            <td align="right">
              <template v-if="userTabs.payoneer.active">
                {{item.amount}}
              </template>
              <template v-else>
                {{item.user_amount}}
              </template>
            </td>
            <td v-if="!userTabs.payoneer.active" align="right">
              {{item.payment_total}}
            </td>
            <td></td>

            <td v-if="userTabs.authorize.active">
              <div class="table-right table-manage-list">

                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                  <template slot="item">
                    <span
                            v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <span class="admin-edit" @click="editTranslate(['mypayments_printInvoice',])"></span>
                       <LinkButton
                               :value="$t('mypayments_printInvoice.localization_value.value')"
                               :type="'print'"
                               :datePicker="true"
                               @changeDate="changeDate"
                               @datePickerBtn="$emit('printInvoice', {id: [item.id], date: dateInvoice})"
                               :popup-class="'mobile-center'"
                               :datePickerRange="false"
                               :datePickerTxt="$t('invoices_print.localization_value.value')"
                               :datePickerFormat="'DD-MM-YYYY'"
                       />
                    </span>

                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-else>
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getMyPayments"
                 :key="index"
            >
              <MyPaymentsTableMobile
                :item="item"
                :userTabs="userTabs"
                :selectedNow="selectedNow"
                @printInvoice="mobilePrintInvoice(item.id)"
                @changeDate="date => this.dateInvoice = date"
                @showTransactionInfo="showTransactionInfo(item.id)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                      v-if="$store.getters.getMyPaymentsCommonList.next_page_url !== null && $store.getters.getMyPayments.length > 0"
                      @click.native="$emit('showMore')"
                      v-bind:class="{'disabled-btn' : $store.getters.getNextMyPaymentsPage}"
                      :count="$store.getters.getMyPaymentsCommonList.total - $store.getters.getMyPaymentsForPage * $store.getters.getMyPaymentsCommonList.current_page < $store.getters.getMyPaymentsForPage ?
                  $store.getters.getMyPaymentsCommonList.total - $store.getters.getMyPaymentsForPage * $store.getters.getMyPaymentsCommonList.current_page:
                  $store.getters.getMyPaymentsForPage"
            />
            <ExportBtn
                @downloadFiles="type => $emit('downloadFiles', type)"
                    class="table-bottom-btn__right"/>
          </div>
        </div>

      </div>
    </template>

    <template v-if="$store.getters.getMyPaymentsLoading === false && $store.getters.getMyPayments.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>


    <TransactionInfo
        @close="closeTransactionInfo"
        v-if="isModalTransactionInfo"
        :userTabs="userTabs"
        :typeInfo="typeInfo"
    />


  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  import TransactionInfo from "../../../../popups/TransactionInfo/TransactionInfo.vue";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import MyPaymentsTableMobile from "./MyPaymentsTableMobile/MyPaymentsTableMobile";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";


  export default {
    name: "MyPaymentsTable",
    components: {
      ShowMore,
      ExportBtn,
      LinkButton,
      TransactionInfo,
      ManagerButton,
      DefaultCheckbox,
      NoResult,
      MyPaymentsTableMobile,
      TableUserColumn,
    },

    mixins: [mixinDetictingMobile],

    props: ['activeTab', 'userTabs', 'selectedNow', 'countFilterParams'],

    data() {
      return {
        dateInvoice: this.$moment().format('DD-MM-YYYY'),

        isModalTransactionInfo: false,

        items: [
          {
            item: 'empty',
            show: false
          },
          {
            item: 'empty',
            show: false
          },
        ],

        typeInfo: 'default'
      }
    },


    mounted() {

    },

    methods: {
      showTransactionInfo(id) {
        let typeDispatch = 'getMyPayments'

        if(this.userTabs.payoneer.active)  {
          this.typeInfo = 'payoneer'
          typeDispatch = 'getMyPaymentsPayoneer'
        } else {
          this.typeInfo = 'default'
        }

        this.$store.dispatch(typeDispatch, id).then(() => {
          this.isModalTransactionInfo = true
        })
      },

      closeTransactionInfo() {
        this.isModalTransactionInfo = false
      },

      changeDate(value) {
        this.dateInvoice = value
      },

      mobilePrintInvoice(id) {
        this.$emit('printInvoice', {id: [id], date: this.dateInvoice})
      },

    },
  }
</script>

<style scoped lang="scss">
  .import-orders-table table td:last-child,
  .import-orders-table table th:last-child {
    width: 270px;
  }

  @media (max-width: 1600px) {
    .table-right__ico {
      margin-left: 10px;
    }
  }

  .table-card__item-btn-i:first-child{
    padding-left: 5px;
  }

  .small-table{
    th, td{
      white-space: nowrap;
    }
    td:last-child{
      text-align: right;
      padding-right: 15px;
    }
  }

  .site-table thead th:first-child,
  .site-table tbody td:first-child{
    padding-left: 8px;
    padding-right: 0;
  }

  .site-table thead th:nth-child(2),
  .site-table tbody td:nth-child(2){
    padding-left: 1px;
  }

</style>
