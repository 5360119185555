<template>
  <modal
      @close="$emit('close')"
      class="transaction-info-modal transaction-info"
  >
    <template slot="header">
      {{myPayment.id}} —
      {{ myPayment.created_at | moment("DD MMM, YYYY") }} at
      {{ myPayment.created_at | moment("HH:mm") }}
    </template>
    <template slot="body">
      <div class="transaction-info__content" v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(translations)"></span>

        <div class="transaction-info__name"
             v-if="!userTabs.payoneer.active"
        >
          <template v-if="
                myPayment.payment_transaction_detail !== null &&
                myPayment.payment_transaction_detail.payment_transaction_contact !== null">
            {{myPayment.payment_transaction_detail.payment_transaction_contact.full_name}}
          </template>
        </div>
        <div class="transaction-info__status" v-if="!userTabs.payoneer.active">
          <div class="transaction-info__status-ico">
            <CheckIco v-if="myPayment.status === CUSTOMERS_PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS"/>
            <StatusIcoBtn :type="'in-progress'" v-if="myPayment.status === CUSTOMERS_PAYMENTS_STATUSES.ON_HOLD_STATUS"/>
            <StatusIcoBtn :type="'cancel'" v-if="myPayment.status === CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS"/>
          </div>
          <div class="transaction-info__status-txt transaction-info__status-txt--green"
               v-if="myPayment.status === CUSTOMERS_PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS">
              {{$t('cuspayments_completed.localization_value.value')}}
          </div>
          <div class="transaction-info__status-txt"
               v-if="myPayment.status === CUSTOMERS_PAYMENTS_STATUSES.ON_HOLD_STATUS">
            {{$t('cuspayments_inProcess.localization_value.value')}}
          </div>
          <div class="transaction-info__status-txt"
               v-if="myPayment.status === CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS">
            {{$t('cuspayments_canceled.localization_value.value')}}
          </div>
        </div>

        <!--<div class="transaction-info__section">-->
          <!--<div class="transaction-info__section-title">-->
            <!--Order Details-->
          <!--</div>-->

          <!--<div class="transaction-info__row">-->
            <!--<div class="transaction-info__col">-->
              <!--<div class="transaction-info__label">-->
                <!--Lot or Invoice Number-->
              <!--</div>-->
              <!--<div class="transaction-info__txt">-->
                <!--Lot: 3785829049-->
              <!--</div>-->
            <!--</div>-->
            <!--<div class="transaction-info__col">-->
              <!--<div class="transaction-info__label">-->
                <!--Tracking Number-->
              <!--</div>-->
              <!--<div class="transaction-info__txt">-->
                <!--3785829049-->
              <!--</div>-->
            <!--</div>-->
          <!--</div>-->

          <!--<div class="transaction-info__row">-->
            <!--<div class="transaction-info__col">-->
              <!--<div class="transaction-info__label">-->
                <!--Name-->
              <!--</div>-->
              <!--<div class="transaction-info__txt">-->
                <!--item#495723-test Item#001-->
              <!--</div>-->
            <!--</div>-->
            <!--<div class="transaction-info__col">-->
              <!--<div class="transaction-info__label">-->
                <!--Etsy, Ebay - Order-->
              <!--</div>-->
              <!--<div class="transaction-info__txt">-->
                <!--#39523:Svitlana Ukrainova (FedEx)-->
              <!--</div>-->
            <!--</div>-->
          <!--</div>-->

        <!--</div>-->

        <div class="transaction-info__section" v-if="myPayment.payment_transaction_detail && myPayment.payment_transaction_detail.payment_transaction_contact">
          <div class="transaction-info__section-title">
            {{$t('cuspayments_customerInformation.localization_value.value')}}
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col" v-if="myPayment.payment_transaction_detail !== null">
              <div class="transaction-info__label">
                {{$t('common_phone.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-if="myPayment.payment_transaction_detail.payment_transaction_contact.phone">
                  {{myPayment.payment_transaction_detail.payment_transaction_contact.phone}}
                </template>
                <template v-else>
                  —
                </template>
              </div>
            </div>
            <div class="transaction-info__col" v-if="
            myPayment.payment_transaction_detail !== null &&
            myPayment.payment_system === userTabs.paypal.value">
              <div class="transaction-info__label">
                {{$t('common_email.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-if="myPayment.payment_transaction_detail.payment_transaction_contact.email">
                  {{myPayment.payment_transaction_detail.payment_transaction_contact.email}}
                </template>
              </div>
            </div>

            <div class="transaction-info__col" v-if="myPayment.payment_transaction_detail !== null">
              <div class="transaction-info__label">
                {{$t('common_address.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-if="myPayment.payment_transaction_detail.payment_transaction_contact.address">
                  {{myPayment.payment_transaction_detail.payment_transaction_contact.address}}
                </template>
              </div>
            </div>

            <div class="transaction-info__col" v-if="myPayment.payment_transaction_detail !== null">
              <div class="transaction-info__label">
                {{$t('common_state.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-if="myPayment.payment_transaction_detail.payment_transaction_contact.state">
                  {{myPayment.payment_transaction_detail.payment_transaction_contact.state}}
                </template>
              </div>
            </div>

            <div class="transaction-info__col" v-if="myPayment.payment_transaction_detail !== null">
              <div class="transaction-info__label">
                {{$t('common_city.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-if="myPayment.payment_transaction_detail.payment_transaction_contact.city">
                  {{myPayment.payment_transaction_detail.payment_transaction_contact.city}}
                </template>
              </div>
            </div>

            <div class="transaction-info__col" v-if="myPayment.payment_transaction_detail !== null">
              <div class="transaction-info__label">
                {{$t('common_country.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-if="myPayment.payment_transaction_detail.payment_transaction_contact.country_code">
                  {{myPayment.payment_transaction_detail.payment_transaction_contact.country_code}}
                </template>
              </div>
            </div>
            <div class="transaction-info__col" v-if="myPayment.payment_transaction_detail !== null">
              <div class="transaction-info__label">
                {{$t('cuspayments_zipCode.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-if="myPayment.payment_transaction_detail.payment_transaction_contact.zip_code">
                  {{myPayment.payment_transaction_detail.payment_transaction_contact.zip_code}}
                </template>
              </div>
            </div>
          </div>
        </div>


        <div class="transaction-info__section">
          <div class="transaction-info__section-title">
            <template v-if="userTabs.paypal.active">
              {{$t('cuspayments_paypalTransactions.localization_value.value')}}
            </template>
            <template v-if="userTabs.payoneer.active">
              {{$t('cuspayments_payoneerTransactions.localization_value.value')}}
            </template>
            <template v-else>
              {{$t('cuspayments_transactions.localization_value.value')}}
            </template>
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('cuspayments_totalAmount.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-if="userTabs.payoneer.active">
                  ${{myPayment.amount}}
                </template>
                <template v-else>
                  ${{myPayment.payment_total}}
                </template>
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('importO_transactionNumber.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{myPayment.transaction_id}}
              </div>
            </div>

<!--            v-if="myPayment.payment_system !== userTabs.braintree.value"-->
            <div class="transaction-info__col" v-if="!userTabs.payoneer.active">
              <div class="transaction-info__label">
                <template v-if="myPayment.payment_system === userTabs.authorize.value">
                  {{$t('mypayments_authorizeFee.localization_value.value')}}
                </template>
                <template v-else-if="myPayment.payment_system === userTabs.braintree.value">
                  {{$t('mypayments_braintreeFee.localization_value.value')}}
                </template>
                <template v-else>
                  {{$t('cuspayments_paypalFee.localization_value.value')}}
                </template>
              </div>

              <div class="transaction-info__txt">

                <template v-if="
                myPayment.transaction_sluice === TRANSACTION_SLUICE.INTERNAL_TRANSACTION_SLUICE &&
                 (myPayment.payment_system === userTabs.authorize.value || myPayment.payment_system === userTabs.braintree.value)">
                  ${{parseFloat(myPayment.payment_commission).toFixed(2)}}
                </template>

                <template v-if="
                myPayment.transaction_sluice === TRANSACTION_SLUICE.EXTERNAL_TRANSACTION_SLUICE &&
                 (myPayment.payment_system === userTabs.authorize.value || myPayment.payment_system === userTabs.braintree.value)">
                  ${{parseFloat(parseFloat(myPayment.payment_commission) +
                  parseFloat(myPayment.warehouse_commission_amount)).toFixed(2)}}
                </template>

                <template v-if="myPayment.payment_system === userTabs.paypal.value">
                  ${{parseFloat(myPayment.payment_commission).toFixed(2)}}
                </template>
              </div>
            </div>

            <div class="transaction-info__col" v-if="!userTabs.payoneer.active">
              <div class="transaction-info__label">
                {{$t('mypayments_customerIp.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-if="myPayment.payment_transaction_detail">
                  {{myPayment.payment_transaction_detail.payer_ip}}
                </template>
                <template v-else>
                  &mdash;
                </template>
              </div>
            </div>

            <div class="transaction-info__col" v-if="myPayment.payment_system === userTabs.authorize.value ||
              myPayment.payment_system === userTabs.braintree.value">
              <div class="transaction-info__label">
                {{$t('mypayments_perTransactionFee.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                ${{$store.getters.getUserSettings.payments.authorize.authorize_per_transaction.value}}
              </div>
            </div>

            <div class="transaction-info__col" v-if="myPayment.payment_system === userTabs.authorize.value ||
              myPayment.payment_system === userTabs.braintree.value">
              <div class="transaction-info__label">
                {{$t('mypayments_aVSResponse.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="myPayment"
                    :deep="'payment_transaction_detail.avs'"
                />
              </div>
            </div>

            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('cuspayments_SKLADUSAFee.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                &mdash;
              </div>
            </div>

            <div class="transaction-info__col" v-if="myPayment.payment_system !== userTabs.paypal.value && !userTabs.payoneer.active">
              <div class="transaction-info__label">
                {{$t('mypayments_fraudFilters.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="myPayment"
                    :deep="'payment_transaction_detail.fraud_filter'"
                />
                <!--                {{myPayment.payment_transaction_detail.fraud_filter}}-->
              </div>
            </div>

            <div class="transaction-info__col" v-if="!userTabs.payoneer.active">
              <div class="transaction-info__label">
                {{$t('cuspayments_total.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                ${{myPayment.user_amount}}
              </div>
            </div>

            <div class="transaction-info__col" v-if="
            myPayment.payment_system !== userTabs.paypal.value && (
            myPayment.status === CUSTOMERS_PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS ||
            myPayment.status === CUSTOMERS_PAYMENTS_STATUSES.ON_HOLD_STATUS)">
              <div class="transaction-info__label">
                {{$t('mypayments_cardCodeResponse.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{$t('mypayments_cVVMatched.localization_value.value')}}
              </div>
            </div>

          </div>
        </div>



      </div>
    </template>
    <template slot="footer">
      <div class="transaction-info__btn">

        <template>
          <div class="transaction-info__btn__btn"
               v-if="myPayment.status === CUSTOMERS_PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS &&
           myPayment.canMakeRefund &&
            myPayment.canMakeComplaint">
            <router-link :to="$store.getters.GET_PATHS.financeRequestReturnCreate + '/' + myPayment.id">
              <MainButton
                  :value="$t('cuspayments_requestRefund.localization_value.value')"
                  class="wfc"
              />
            </router-link>
          </div>
        </template>

      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import CheckIco from '../../../../../../public/img/common/check.svg?inline'
  import {CUSTOMERS_PAYMENTS_STATUSES, TRANSACTION_SLUICE} from "../../../../../staticData/staticVariables";
  import StatusIcoBtn from "../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import MainButton from "@/components/UI/buttons/MainButton/MainButton";
  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";



  export default {
    name: "TransactionInfo",
    components: {
      ValueHelper,
      MainButton,
      Modal,
      CheckIco,
      StatusIcoBtn,
    },

    props: ['userTabs', 'typeInfo'],

    watch: {
      userTabs(newVal) {
        this.userTabs = newVal
      },
    },

    computed: {
      myPayment() {
       return this.$store.getters.getMyPaymentsItem
      },

      // isTypeInfoPayoneer() {
      //   return this.typeInfo === 'payoneer'
      // }
    },

    data() {
      return {
        CUSTOMERS_PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES,
        TRANSACTION_SLUICE: TRANSACTION_SLUICE,
        translations: [
          'cuspayments_completed',
          'cuspayments_orderDetails',
          'cuspayments_lotInvoiceNumber',
          'cuspayments_trackingNumber',
          'cuspayments_name',
          'cuspayments_order',
          'cuspayments_customerInformation',
          'common_phone',
          'common_email',
          'common_address',
          'common_city',
          'common_country',
          'cuspayments_zipCode',
          'cuspayments_paypalTransactions',
          'cuspayments_transactions',
          'cuspayments_paypalTransactions',
          'cuspayments_totalAmount',
          'cuspayments_total',
          'cuspayments_paypalFee',
          'mypayments_perTransactionFee',
          'cuspayments_SKLADUSAFee',
          'cuspayments_requestRefund',
          'mypayments_customerIp',
          'mypayments_aVSResponse',
          'mypayments_fraudFilters',
          'mypayments_cardCodeResponse',
        ]
      }
    },
  }

</script>

<style lang="scss">

</style>
