<template>
  <div class="fragment">


    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator"
           v-bind:class="{'loading-tabs': $store.getters.getMyPaymentsLoading === true,}">
        <div class="content-tabs__item"
             v-if="userTabs.paypal.permissionVisible"
             v-bind:class="{active: userTabs.paypal.active}"
             @click="changeTab('paypal')"
        >
          <div class="content-tabs__ico">
            {{ userTabs.paypal.name }}
          </div>
        </div>
        <div class="content-tabs__item"
             v-if="userTabs.authorize.permissionVisible"
             v-bind:class="{active: userTabs.authorize.active}"
             @click="changeTab('authorize')"
        >
          <div class="content-tabs__ico">
            {{ userTabs.authorize.name }}
          </div>
        </div>
        <div class="content-tabs__item"
             v-if="userTabs.braintree.permissionVisible"
             v-bind:class="{active: userTabs.braintree.active}"
             @click="changeTab('braintree')"
        >
          <div class="content-tabs__ico">
            {{ userTabs.braintree.name }}
          </div>
        </div>
        <div class="content-tabs__item"
             v-if="userTabs.payoneer.permissionVisible"
             v-bind:class="{active: userTabs.payoneer.active}"
             @click="changeTab('payoneer')"
        >
          <div class="content-tabs__ico">
            {{ userTabs.payoneer.name }}
          </div>
        </div>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block line-block-custom">
        <div class="btn-left-block-i">
          <DefaultCheckbox
              :label="$t('warehouseManagement_selectAll.localization_value.value')"
              @input="selectAll"
          />
        </div>

        <div class="btn-left-block-i">
          <div class="btn-left-block__list">
            <div class="btn-left-block__list__i" v-if="userTabs.authorize.active">
              <PrintInvoice
                  class="btn-left-block-i--link"
                  :datePicker="true"
                  :datePickerRange="false"
                  :datePickerFormat="'DD-MM-YYYY'"
                  @changeDate="changeDate"
                  @datePickerBtn="$emit('printInvoice', dateInvoice)"
              />
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import PrintInvoice from "../../../../../../coreComponents/PrintInvoice/PrintInvoice";




  export default {
    name: "MyPaymentsHead",
    components: {
      ToggleFilterButton,
      DefaultCheckbox,
      PrintInvoice,
    },

    props: [
      'showFilter',
      'activeTab',
      'userTabs',
      'countFilterParams',
    ],

    watch: {
      activeTab(newVal) {
        this.tab = newVal
      }
    },

    data(){
      return{
        tab: this.activeTab,
        dateInvoice: this.$moment().format('DD-MM-YYYY'),
      }
    },


    methods: {

      changeTab(id) {
        this.$emit('changeTab', id)
      },

      changeDate(value) {
        this.dateInvoice = value
      },

      selectAll(val) {
        this.$emit('selectAll', val)
      },
    }
  }
</script>

