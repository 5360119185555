<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['mypayments_number'])"></span>
        <DefaultInput
            :label="$t('mypayments_number.localization_value.value')"
            :type="'text'"
            v-model="order"
        />
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>
        <SearchSelect
            :options="options"
            :label="$t('shop_user.localization_value.value')"
            :functionSearch="functionSearch"
            :selected="userName"
            :typeSelect="'users'"
            :userEmail="true"
            @change="changeUsersFilter"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['mypayments_created'])"></span>

        <DatePickerDefault
            :label="$t('mypayments_created.localization_value.value')"
            v-model="date"
        >
          <template slot="body">
            <date-picker
                v-model="date"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy ~ mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['mypayments_customer'])"></span>

        <DefaultInput
            :label="$t('mypayments_customer.localization_value.value')"
            :type="'text'"
            v-model="customer"
        />
      </div>
      <div class="table-filter__item" v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['mypayments_transaction'])"></span>

        <DefaultInput
            :label="$t('mypayments_transaction.localization_value.value')"
            :type="'text'"
            v-model="transactionNumber"
        />
      </div>
      <div class="table-filter__item" v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['mypayments_sum'])"></span>

        <DefaultInput
            :label="$t('mypayments_sum.localization_value.value')"
            :type="'text'"
            v-model="sum"
        />
      </div>
      <div class="table-filter__item" v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['mypayments_sumToPay'])"></span>

        <DefaultInput
            :label="$t('mypayments_sumToPay.localization_value.value')"
            :type="'text'"
            v-model="sumToPay"
        />
      </div>
    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import DatePicker from 'vue2-datepicker'
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";

  export default {
    name: "MyPaymentsFilter",
    components: {
      FilterBlock,
      MainButton,
      DatePickerDefault,
      DatePicker,
      DefaultInput,
      SearchSelect,
    },

    props: [
      'showFilter',
      'filterGetParams',
    ],

    mixins: [filterFunc, usersFunc],

    data(){
      return {
        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY'): '',
        order: this.filterGetParams.order ? this.filterGetParams.order : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',
        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        customer: this.filterGetParams.customer ? this.filterGetParams.customer : '',
        transactionNumber: this.filterGetParams.transactionNumber ? this.filterGetParams.transactionNumber : '',
        sum: this.filterGetParams.sum ? this.filterGetParams.sum : '',
        sumToPay: this.filterGetParams.sumToPay ? this.filterGetParams.sumToPay : '',

        options: [],

        filterItems: [
          'date',
          'order',
          'userName',
          'userId',
          'customer',
          'transactionNumber',
          'sum',
          'sumToPay',
        ]
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.order = newVal.order ? newVal.order : ''
        this.customer = newVal.customer ? newVal.customer : ''
        this.transactionNumber = newVal.transactionNumber ? newVal.transactionNumber : ''
        this.sum = newVal.sum ? newVal.sum : ''
        this.sumToPay = newVal.sumToPay ? newVal.sumToPay : ''
        this.userName = newVal.userName ? newVal.userName : ''
        this.userId = newVal.userId ? newVal.userId : ''
      },
    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      functionSearch(value, loading) {
        loading(true)
        this.getFilteredUsers(value, this, 'options', loading, true)
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterItems)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterItems)
        data.date = this.changeDateParams(this, 'date')

        this.$emit('changeFilter', data)
      },

      changeUsersFilter(value) {
        this.userId = value.id
        this.userName = value.contacts[0].user_full_name
      },
    },
  }
</script>

<style scoped>

</style>
